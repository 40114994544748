import React from 'react'

// Logo, white

export default () => (
	<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 599.5 130.9" style={{ enableBackground: 'new 0 0 599.5 130.9' }} height="36px" className="logo-svg logo-svg-white">
		<g id="logo">
			<g>
				<path className="st0" d="M0,11.8h28.3L48,64.9l7.1,20.7h0.7l7.1-20.7l19.2-53.1h28.1v118.1H85.3V86.6c0-2.9,0.1-6.1,0.4-9.6
					c0.2-3.5,0.5-7,0.9-10.6c0.4-3.6,0.7-7.1,1.1-10.5c0.4-3.4,0.7-6.6,1.1-9.5h-0.7l-9.8,27.4l-18.1,45.8H49.5L31.3,73.8l-9.4-27.4
					h-0.7c0.4,2.9,0.7,6.1,1.1,9.5c0.4,3.4,0.7,6.9,1,10.5c0.3,3.6,0.6,7.1,0.8,10.6c0.2,3.5,0.4,6.7,0.4,9.6v43.3H0V11.8z"/>
			</g>
			<g>
				<path className="st0" d="M223.3,2.9h26.6v31.2l-1.3,16.1c3.4-3,7.3-5.8,11.8-8.4c4.5-2.6,9.8-3.9,15.9-3.9c9.8,0,16.9,3.2,21.3,9.6
					c4.4,6.4,6.6,15.3,6.6,26.6v55.8h-26.6V77.6c0-6.5-0.9-11-2.6-13.4c-1.8-2.4-4.6-3.6-8.4-3.6c-3.4,0-6.3,0.8-8.7,2.3
					c-2.4,1.5-5.1,3.7-8,6.4v60.7h-26.6V2.9z"/>
			</g>
			<g>
				<path className="st0" d="M381.4,57.6h-12.3V37.9l13.8-1.1l3.1-23.9H408v23.9h21.6v20.8H408v36.1c0,5.1,1.1,8.7,3.2,11
					c2.1,2.2,4.9,3.4,8.4,3.4c1.4,0,2.9-0.2,4.4-0.5c1.5-0.4,2.9-0.8,4.1-1.3l4.2,19.4c-2.4,0.7-5.3,1.4-8.5,2.2
					c-3.3,0.7-7.1,1.1-11.4,1.1c-5.6,0-10.3-0.8-14.2-2.5c-3.9-1.7-7.1-4-9.6-7.1c-2.5-3-4.3-6.7-5.4-11c-1.2-4.3-1.7-9-1.7-14.2V57.6
					z"/>
			</g>
			<g>
				<path className="st0" d="M184.3,40.6h-0.6h-29h-29h-0.6c-0.3,0-0.5,0.2-0.5,0.5c0,0.1,0,0.1,0,0.2l0.2,0.5c7.4,16.3,7.5,26,7.5,31.3
					c0,15.3-5.9,20.5-5.9,33.5c0,12.8,4.8,18.7,10.5,22.9c0.2,0.2,0.5,0.3,0.8,0.3h16.9h16.9c0.3,0,0.6-0.1,0.8-0.3
					c5.7-4.2,10.5-10.1,10.5-22.9c0-13.1-5.9-18.2-5.9-33.5c0-5.3,0-15.1,7.5-31.3l0.2-0.5c0-0.1,0-0.1,0-0.2
					C184.8,40.8,184.6,40.6,184.3,40.6z M168.8,122.7c-0.1,0.1-0.2,0.2-0.4,0.2h-1.9c-0.2,0-0.3-0.1-0.3-0.2c0,0,0-0.1,0-0.2
					c0-0.1,0-0.2,0.1-0.3c3-2.7,5.1-6.5,5.1-15.1c0-5.2-0.7-9.3-2.1-13.7c-1.6-5.3-2.8-11.2-2.8-20.3c0-6.6,0.9-12.5,5.1-24.7l0.2-0.4
					c0.1-0.2,0.3-0.3,0.5-0.3h1.3c0.3,0,0.5,0.2,0.5,0.5l-0.2,0.5c-4.2,12.1-4.2,20.1-4.2,24.5c0,9,1.8,15,3.4,20.3
					c1.3,4.3,2.4,8.1,2.4,13.3C175.6,115.8,171.7,119.5,168.8,122.7z"/>
			</g>
			<g>
				<path className="st0" d="M207.6,40.6h-16.2c-0.2,0-0.4,0.1-0.5,0.3l-0.2,0.5c0,0.1-0.1,0.1-0.1,0.2c0,0.1,0,0.1,0,0.1
					c0.1,0.1,0.2,0.2,0.3,0.2l13.8,0.1l-2.1,4.1c-0.8,1.8-1.5,3.6-2.2,5.4c-0.7,1.8-1.2,3.7-1.8,5.6c-0.5,1.9-1,3.8-1.3,5.7
					c-0.2,1-0.4,1.9-0.5,2.9c-0.1,1-0.3,2-0.3,2.9c-0.1,1-0.1,2-0.1,2.9c0,0.5,0,1,0,1.5l0,1.4c0,1.9,0.2,4,0.4,6
					c0.3,2,0.7,3.9,1.2,5.8c1,3.8,2.3,7.4,3,11.1c0.4,1.8,0.6,3.7,0.7,5.5c0,0.9,0,1.9,0,2.7c0,1-0.1,2-0.2,2.8
					c-0.1,1.8-0.5,3.6-0.9,5.3c-0.5,1.7-1.1,3.4-2,4.9c-0.9,1.7-2.2,3.1-3.5,4.4h-9.7v0c-0.2,0-0.4,0.1-0.5,0.3l-0.1,0.2
					c-1.1,2.1-2.4,4.1-3.8,5.7c-0.1,0.1-0.1,0.1-0.1,0.2c0,0.1,0,0.1,0,0.1c0.1,0.1,0.2,0.2,0.3,0.2h9.7l0,0l3.5,0
					c0.4,0,0.8-0.1,1.1-0.3v0c1.6-1.1,3.2-2.4,4.6-3.9c1.4-1.5,2.7-3.2,3.7-5c1-1.8,1.7-3.8,2.2-5.8c0.5-2,0.8-4,0.9-6.1
					c0.1-1.1,0.1-2,0.1-3c0-1.1-0.1-2-0.1-3.1c-0.2-2-0.5-4-0.9-6c-0.9-3.9-2.3-7.5-3.4-11.1c-0.5-1.8-0.9-3.6-1.3-5.5
					c-0.3-1.9-0.5-3.7-0.6-5.6l-0.1-1.4c0-0.4,0-0.9,0-1.4c0-1,0-1.9,0-2.9c0-0.9,0.1-1.9,0.2-2.8c0-0.9,0.2-1.9,0.3-2.8
					c0.2-1.9,0.7-3.7,1.1-5.6c0.5-1.8,1-3.7,1.6-5.5c0.7-1.8,1.2-3.6,1.9-5.4l2.2-5.3c0,0,0,0,0,0c0-0.1,0-0.1,0-0.2
					C208.1,40.8,207.8,40.6,207.6,40.6z"/>
			</g>
			<g>
				<path className="st0" d="M336.5,27.7c-4.5,0-8.2-1.3-11.1-3.9c-2.9-2.6-4.3-5.9-4.3-10.1c0-4.1,1.4-7.4,4.3-10
					C328.4,1.3,332,0,336.5,0c4.6,0,8.3,1.3,11.1,3.8c2.8,2.5,4.3,5.9,4.3,10c0,4.1-1.4,7.5-4.3,10.1
					C344.8,26.4,341.1,27.7,336.5,27.7z M323.3,41h26.6v89.9h-26.6V41z"/>
			</g>
		</g>
		<g id="beta">
			<g>
				<path className="st0" d="M485.5,130.9c-2.2,0-4.3-0.3-6.1-1c-1.8-0.7-3.5-1.7-5.2-2.9l2.6-4.6c1.4,1.2,2.8,2,4.1,2.6
					c1.4,0.6,2.8,0.8,4.4,0.8c2.2,0,3.9-0.6,5-1.9c1.1-1.3,1.7-2.8,1.7-4.5c0-1.5-0.4-2.8-1.2-3.8c-0.8-1-1.7-1.9-2.9-2.6
					c-1.2-0.7-2.4-1.5-3.8-2.1c-1.4-0.7-2.6-1.4-3.8-2.3c-1.2-0.9-2.1-1.9-2.9-3.1c-0.8-1.2-1.2-2.7-1.2-4.6c0-1.8,0.4-3.4,1.1-4.6
					s1.5-2.5,2.4-3.7c0.9-1.2,1.7-2.4,2.4-3.6c0.7-1.2,1.1-2.7,1.1-4.4c0-2-0.5-3.7-1.6-5c-1.1-1.3-2.8-2-5-2c-2.9,0-5.1,1-6.7,3.1
					c-1.6,2.1-2.4,5.2-2.4,9.3v40h-6.6V88.7c0-5,1.4-9,4.1-12c2.7-3,6.6-4.5,11.6-4.5c2.1,0,3.9,0.3,5.6,0.9c1.6,0.6,3,1.5,4.1,2.5
					c1.1,1.1,2,2.3,2.5,3.7c0.6,1.4,0.8,2.9,0.8,4.5c0,2-0.4,3.7-1.1,5.2c-0.7,1.4-1.6,2.7-2.5,3.9c-0.9,1.2-1.8,2.3-2.5,3.4
					c-0.7,1.1-1.1,2.3-1.1,3.7c0,1.3,0.4,2.3,1.2,3.2c0.8,0.9,1.7,1.6,2.9,2.3c1.2,0.7,2.4,1.3,3.8,2c1.4,0.7,2.6,1.5,3.8,2.5
					c1.2,1,2.1,2.2,2.9,3.6c0.8,1.4,1.2,3.2,1.2,5.4c0,1.7-0.3,3.3-0.9,4.7c-0.6,1.4-1.4,2.7-2.6,3.8c-1.1,1.1-2.5,1.9-4,2.5
					C489.3,130.6,487.5,130.9,485.5,130.9z"/>
				<path className="st0" d="M523,130.9c-2.6,0-5.1-0.5-7.3-1.4c-2.3-0.9-4.2-2.3-5.9-4c-1.7-1.7-3-3.9-4-6.4c-1-2.5-1.4-5.4-1.4-8.6
					c0-3.2,0.5-6.1,1.5-8.6c1-2.5,2.3-4.7,3.9-6.4c1.6-1.8,3.5-3.1,5.6-4c2.1-0.9,4.2-1.4,6.5-1.4c2.5,0,4.7,0.4,6.6,1.3
					c1.9,0.9,3.6,2.1,4.9,3.7c1.3,1.6,2.3,3.5,3,5.8c0.7,2.2,1,4.7,1,7.5c0,0.7,0,1.4,0,2c0,0.7-0.1,1.2-0.2,1.7h-26.2
					c0.3,4.2,1.6,7.5,3.9,9.9c2.3,2.4,5.3,3.6,9.1,3.6c1.9,0,3.6-0.3,5.2-0.8c1.6-0.6,3.1-1.3,4.5-2.2l2.3,4.3c-1.7,1.1-3.6,2-5.7,2.8
					C528,130.5,525.6,130.9,523,130.9z M531.5,107.4c0-3.9-0.8-6.9-2.5-9c-1.7-2.1-4-3.1-7.1-3.1c-1.4,0-2.7,0.3-4,0.8
					c-1.3,0.5-2.4,1.3-3.4,2.4c-1,1-1.9,2.3-2.5,3.8c-0.7,1.5-1.1,3.2-1.3,5.1H531.5z"/>
				<path className="st0" d="M558.3,130.9c-2.1,0-3.8-0.3-5.2-1c-1.4-0.6-2.6-1.5-3.4-2.6s-1.5-2.5-1.9-4.1c-0.4-1.6-0.6-3.4-0.6-5.3V96.4
					h-5.8v-5l6.1-0.4l0.8-10.9h5.5V91h10.5v5.4h-10.5V118c0,2.4,0.4,4.3,1.3,5.6s2.4,2,4.7,2c0.7,0,1.4-0.1,2.2-0.3
					c0.8-0.2,1.5-0.5,2.2-0.7l1.3,5c-1.1,0.4-2.2,0.7-3.5,1C560.8,130.7,559.5,130.9,558.3,130.9z"/>
				<path className="st0" d="M580.4,130.9c-3.3,0-6-1-8.1-2.9c-2.2-1.9-3.2-4.6-3.2-8.2c0-4.3,1.9-7.5,5.7-9.8c3.8-2.3,9.8-3.9,18.2-4.8
					c0-1.2-0.1-2.4-0.4-3.6c-0.2-1.2-0.7-2.2-1.2-3.1c-0.6-0.9-1.4-1.6-2.4-2.2c-1-0.6-2.4-0.8-4-0.8c-2.2,0-4.3,0.4-6.3,1.3
					c-2,0.9-3.7,1.8-5.3,2.9l-2.6-4.6c1.8-1.2,4-2.3,6.6-3.4c2.6-1.1,5.5-1.6,8.6-1.6c4.7,0,8.2,1.5,10.3,4.4
					c2.1,2.9,3.2,6.8,3.2,11.6v23.8h-5.4l-0.6-4.6h-0.2c-1.9,1.5-3.9,2.9-6,4C585.1,130.3,582.8,130.9,580.4,130.9z M582.3,125.6
					c1.9,0,3.6-0.4,5.3-1.3c1.7-0.9,3.4-2.2,5.3-3.9v-10.8c-3.3,0.4-6,0.9-8.2,1.5c-2.2,0.6-4,1.3-5.4,2.1c-1.4,0.8-2.3,1.7-3,2.8
					c-0.6,1-0.9,2.2-0.9,3.4c0,2.2,0.7,3.8,2,4.8S580.4,125.6,582.3,125.6z"/>
			</g>
		</g>
	</svg>
)
